import Button, {ButtonProps} from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

type LoadingProps = {
  isLoading?: boolean
};

export default function LoadingButton(props: ButtonProps & LoadingProps) {
  const {isLoading, disabled, ...buttonProps} = props;
  return <Button
    disabled={disabled || isLoading}
    {...buttonProps}
  >
    {props.isLoading ?
     <CircularProgress size={24}/> :
     <>
      {props.children}
     </>
    }
  </Button>;
}
