import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Person from '@material-ui/icons/Person';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {MenuItem} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import FirebaseService from "../Services/FirebaseService";

const lightColor = 'rgba(255, 255, 255, 0.7)';
const headerBackground = '#18202c';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryBar: {
      backgroundColor: headerBackground,
        padding: theme.spacing(1.1)
    },
    secondaryBar: {
      zIndex: 0,
        backgroundColor: headerBackground
    },
    menuButton: {
      marginLeft: -theme.spacing(),
    },
    iconButtonAvatar: {
      padding: 4
    },
    link: {
      textDecoration: 'none',
        color: lightColor,
        '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    }
  })
);

type HeaderProps = {
  onDrawerToggle(): void
};

export default function Header(props: HeaderProps) {
  const {onDrawerToggle} = props;
  const classes = useStyles();

  // account menu props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function logout() {
    handleClose();
    await FirebaseService.auth().signOut();
  }

  function AccountMenu() {
    return <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>;
  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} className={classes.primaryBar}>
        <Toolbar>
          <Grid container spacing={8} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon/>
                </IconButton>
              </Grid>
            </Hidden>
            <Toolbar>
              <Grid container alignItems="center" spacing={8}>
                <Grid item xs>
                  <Typography color="inherit" variant="h5">
                    {/*Users*/}
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
            <Grid item xs/>
            <Grid item>
              <Tooltip title="Alerts • No alters">
                <IconButton color="inherit">
                  <NotificationsIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Account">
                <React.Fragment>
                  <IconButton
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleMenu}
                  >
                    <Person/>
                  </IconButton>
                  <AccountMenu />
                </React.Fragment>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
