import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import {Input} from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 100,
  },
  input: {
    width: '100%'
  }
});

export default function PromotionImage(props: {
  url: string
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        {props.url &&
         <CardMedia
           className={classes.media}
           image={props.url}
         />
        }
      </CardActionArea>
    </Card>
  );
}
