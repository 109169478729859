import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import FirebaseService from '../Services/FirebaseService'
import { Redirect } from 'react-router'
import LoadingButton from '../Components/LoadingButton'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  logo: {
    margin: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: 200
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing()
  },
  submit: {
    marginTop: theme.spacing(3)
  }
})

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    isLoading: false
  }

  handleEmailChange = (e) => {
    this.setState({email: e.target.value})
  }

  handlePasswordChange = (e) => {
    this.setState({password: e.target.value})
  }

  performLogin = () => {
    const {email, password} = this.state
    this.setState({isLoading: true})
    FirebaseService.login(email, password)
      .then(() => console.log('SUCCESS'))
      .catch((e) => console.log('ERROR: ', e))
      .finally(() => this.setState({isLoading: false}))
  }

  render () {
    const {classes} = this.props

    if (this.props.isAuthenticated) {
      return <Redirect to={'/users'} />
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img
            alt="Jetline Enterprises Inc."
            src="/images/logo-white-bg.png"
            className={classes.logo}
          />
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
            </FormControl>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.performLogin()}
              isLoading={this.state.isLoading}
            >
              Login
            </LoadingButton>
          </form>
        </Paper>
      </main>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoginPage)
