import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Router from "./HookedBrowserRouter"
import {PrivateRoute} from "./PrivateRoute";
import home from "../Pages/HomePage";
import Login from "../Pages/LoginPage";
import FirebaseService from "../Services/FirebaseService";
import withRoot from "../withRoot";
import PasswordResetPage from "../Pages/PasswordResetPage";

class RootComponent extends Component {
  state = {
    isAuthenticated: false,
    initializing: true
  };

  componentDidMount(): void {
    FirebaseService.onAuthStateChangedListener = (isAuthenticated: boolean) => {
      this.setState({isAuthenticated, initializing: false})
    }
  }

  componentWillUnmount(): void {
    FirebaseService.onAuthStateChangedListener = undefined;
  }

  render() {
    if (this.state.initializing) {
      // todo: display CircularProgress here
      return null
    }
    return (
      <Router>
        <Switch>
          <Route exact path="/set-password" component={PasswordResetPage} />
          <Route path="/login" render={() => <Login isAuthenticated={this.state.isAuthenticated}/>}/>

          <PrivateRoute path="/" component={home} isAuthenticated={this.state.isAuthenticated}/>
        </Switch>
      </Router>
    );
  }
}

export default withRoot(RootComponent);
