import React from 'react'
import {BrowserRouter, Route, RouteComponentProps} from 'react-router-dom'

export const RouterContext = React.createContext<RouteComponentProps|undefined>(undefined);

const HookedBrowserRouter = ({ children }: any) => (
  <BrowserRouter>
    <Route>
      {(routeProps:RouteComponentProps) => (
        <RouterContext.Provider value={routeProps}>
          {children}
        </RouterContext.Provider>
      )}
    </Route>
  </BrowserRouter>
);

export default HookedBrowserRouter
