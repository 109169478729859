import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import * as _ from "lodash";
import FirebaseService from "../Services/FirebaseService";
import LoadingButton from "../Components/LoadingButton";
import useSnackbar from "../Hooks/useSnackbar";

const useStyles = makeStyles((theme: Theme) =>
 createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: 'white',
      borderRadius: 10,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    formControl: {
      minWidth: 200,
    },
    button: {
      marginTop: theme.spacing(5),
      marginLeft: theme.spacing(2)
    }
  }),
);

export interface User {
  firstName: string,
  lastName: string,
  dateOfBirth: Date | undefined,
  gender: string,
  email: string,
  weight: number,
  billingAddress: string,
  phoneNo: string,
  homeAirport: string,
  passportDLPhoto: string,
  photo: string | undefined,
  emergencyContact: string | undefined
}

const optionalUserProperties = [
  'photo',
  'emergencyContact'
];

export default function UserEditPage(props: any) {
  const classes = useStyles();

  // user props
  const [user, setUser] = React.useState<User>(
    {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      gender: '',
      email: '',
      weight: 0,
      billingAddress: '',
      phoneNo: '',
      homeAirport: '',
      passportDLPhoto: '',
      photo: undefined,
      emergencyContact: undefined
    }
  );

  // loading props
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  interface UnsetProperty {
    [key: string]: boolean
  }

  // unset required properties
  const [unsetProps, setUnsetProps] = React.useState<UnsetProperty>(
    {}
  );

  const snackbar = useSnackbar();

  type SelectChangeEvent = { name?: string; value: unknown }

  const handleChange = (name: keyof User) => (event: React.ChangeEvent<HTMLInputElement | SelectChangeEvent>) => {
    setUser({...user, [name]: event.target.value});
  };

  const validateForm = () => {
    let unset: UnsetProperty = {};
    _.forOwn(user, function(value, key) {
      if (!value && !_.includes(optionalUserProperties, key)) {
        unset[key] = true
      }
    });
    setUnsetProps(unset);

    return _.isEmpty(unset)
  };

  const saveUser = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return
    }

    try {
      setIsLoading(true);
      await FirebaseService.registerNewUser(user);
      navigateToUserListPage()
    } catch (e) {
      snackbar.showMessage('Error creating user: ' + e.message);
      console.error(e)
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToUserListPage = () => props.history.push('/users');

  return (
    <Container className={classes.container}>

      <Typography variant="h6" gutterBottom>
        Create New User
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={unsetProps.firstName}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            value={user.firstName}
            onChange={handleChange('firstName')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={unsetProps.lastName}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            value={user.lastName}
            onChange={handleChange('lastName')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            error={unsetProps.billingAddress}
            id="billingAddress"
            name="billingAddress"
            label="Billing Address"
            fullWidth
            value={user.billingAddress}
            onChange={handleChange('billingAddress')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.formControl}
            required
            error={unsetProps.dateOfBirth}
            id="dateOfBirth"
            label="Date of Birth"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={user.dateOfBirth}
            onChange={handleChange('dateOfBirth')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            className={classes.formControl}
            required
            error={unsetProps.gender}
          >
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Select
              value={user.gender}
              onChange={handleChange('gender')}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
            >
              <MenuItem value={'male'}>Male</MenuItem>
              <MenuItem value={'female'}>Female</MenuItem>
              <MenuItem value={'N/A'}>Not Specified</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.formControl}
            required
            error={unsetProps.email}
            id="email"
            name="email"
            label="Email"
            type="email"
            value={user.email}
            onChange={handleChange('email')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            className={classes.formControl}
            error={unsetProps.weight}
          >
            <Input
              id="adornment-weight"
              value={user.weight}
              type='number'
              onChange={handleChange('weight')}
              endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
              aria-describedby="weight-helper-text"
              inputProps={{
                'aria-label': 'Weight',
              }}
            />
            <FormHelperText id="weight-helper-text">Weight</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.formControl}
            required
            error={unsetProps.phoneNo}
            id="phoneNo"
            name="phoneNo"
            label="Phone Number"
            value={user.phoneNo}
            onChange={handleChange('phoneNo')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.formControl}
            required
            error={unsetProps.homeAirport}
            id="homeAirport"
            name="homeAirport"
            label="Home Airport"
            value={user.homeAirport}
            onChange={handleChange('homeAirport')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            required
            error={unsetProps.passportDLPhoto}
            id="passportDLPhoto"
            name="passportDLPhoto"
            label="Passport/DL Photo URL"
            fullWidth
            value={user.passportDLPhoto}
            onChange={handleChange('passportDLPhoto')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            id="emergencyContact"
            name="emergencyContact"
            label="Emergency Contact"
            fullWidth
            value={user.emergencyContact}
            onChange={handleChange('emergencyContact')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            id="photo"
            name="photo"
            label="Photo URL"
            fullWidth
            value={user.photo}
            onChange={handleChange('photo')}
          />
        </Grid>

        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <Button
            className={classes.button}
            variant="text"
            color="default"
            onClick={() => navigateToUserListPage()}
          >
            Cancel
          </Button>

          <LoadingButton
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => saveUser()}
            isLoading={isLoading}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}
