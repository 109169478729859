import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, FormControl, makeStyles, Theme} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import LoadingButton from "./LoadingButton";
import FirebaseService from "../Services/FirebaseService";
import useSnackbar from "../Hooks/useSnackbar";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSectionLabel: {
      alignSelf: 'flex-end'
    }
  }
));

interface MissionConfirmDialogProps {
  open: boolean,
  onClose: () => void,
  missionId: string,
  price: number
}

interface Aircraft {
  model: string,
  location: string
}

export interface MissionConfirmParams {
  missionId: string,
  operator: string,
  quotedPrice: number,
  aircraft: Aircraft
}

export default function MissionConfirmDialog(props: MissionConfirmDialogProps) {
  const classes = useStyles();

  // mission-confirm props
  const initialState = {
    missionId: props.missionId,
    operator: '',
    quotedPrice: props.price,
    aircraft: {
      model: '',
      location: ''
    }
  };
  const [missionConfirmParams, setMissionConfirmParams] = React.useState<MissionConfirmParams>(initialState);

  // isLoading props
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const snackbar = useSnackbar();

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setMissionConfirmParams(_.set({...missionConfirmParams}, name, event.target.value));
  };

  function allValuesSet(object: object) {
    let allSet = true;
    _.forOwn(object, function (value, key) {
      if (!value) {
        allSet = false;
      }
    });
    return allSet;
  }

  function onClose() {
    setMissionConfirmParams(initialState);
    setIsLoading(false);
    props.onClose();
  }

  async function confirmMission() {
    try {
      setIsLoading(true);
      // convert price to cents
      const quotedPrice = missionConfirmParams.quotedPrice * 100;
      const params = {...missionConfirmParams, quotedPrice};
      await FirebaseService.confirmMission(params);
      onClose()
    } catch (e) {
      snackbar.showMessage('Error confirming mission: ' + e.message);
      console.error(e)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Dialog open={props.open} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth>
        <DialogTitle id="form-dialog-title">Confirm Mission</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={2}
                  className={classes.formSectionLabel}
            >
              <FormLabel component='legend'>
                Flight:
              </FormLabel>
            </Grid>

            <Grid item xs={12} sm={5}>
              <TextField
                autoFocus
                label="Operator"
                value={missionConfirmParams.operator}
                onChange={handleChange('operator')}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormControl
                fullWidth
                required
              >
                <InputLabel htmlFor="adornment-price">Quoted Price</InputLabel>
                <Input
                  id="adornment-price"
                  value={missionConfirmParams.quotedPrice}
                  type='number'
                  onChange={handleChange('quotedPrice')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}
                  className={classes.formSectionLabel}
            >
              <FormLabel component='legend'>
                Aircraft:
              </FormLabel>
            </Grid>

            <Grid item xs={12} sm={5}>
              <TextField
                label="Model"
                value={missionConfirmParams.aircraft.model}
                onChange={handleChange('aircraft.model')}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={5}>
            <TextField
                label="Location"
                value={missionConfirmParams.aircraft.location}
                onChange={handleChange('aircraft.location')}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color="primary"
            disabled={!allValuesSet(missionConfirmParams) || !allValuesSet(missionConfirmParams.aircraft)}
            isLoading={isLoading}
            onClick={() => confirmMission()}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
