import React, {useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import LoadingButton from '../Components/LoadingButton';
import FirebaseService, {Collection} from '../Services/FirebaseService';
import _ from 'lodash'
import {Toolbar} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import AlertDialog from '../Components/AlertDialog';
import PromotionForm, {submitPromotionForm} from "../Components/Promotion/PromotionForm";
import useSnackbar from "../Hooks/useSnackbar";

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      container: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        borderRadius: 10,
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(3),
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
      dense: {
        marginTop: 19,
      },
      menu: {
        width: 200,
      },
      toolbar: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey['300'],
        paddingBottom: theme.spacing(),
        paddingLeft: 0,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        width: '100%'
      },
      toolbarButton: {
        marginRight: theme.spacing(2)
      },
      backIcon: {
        marginLeft: 0,
        marginRight: theme.spacing(3)
      },
      buttonIcon: {
        marginLeft: theme.spacing(1),
        fontSize: 20,
      },
      green: {
        color: green['500']
      },
      red: {
        color: red['600']
      }
    }),
);

export interface PromotionCategoryProps {
  category: string,
  price?: number
}

// todo: reuse from /App/Constants/AccountLevel.js
type AccountLevel = 'Test' | 'Basic';

export interface PromotionProps {
  name: string,
  headline: string,
  description: string,
  backgroundImage: string,
  origin: string[],
  destination: string[],
  visible: boolean,
  deleted: boolean,
  priorityLevel: number,
  accountLevels: AccountLevel[],
  categories?: PromotionCategoryProps[],
  startDate?: Date,
  endDate?: Date,
  validDays?: number[]
}

const emptyPromotion: PromotionProps = {
  name: '',
  headline: '',
  description: '',
  backgroundImage: '',
  origin: [],
  destination: [],
  visible: true,
  deleted: false,
  priorityLevel: 0,
  accountLevels: ['Basic', 'Test'],
  validDays: [1, 2, 3, 4, 5, 6, 7]
};

export interface AirportProps {
  name: string,
  code: string,
  status: string
}

export interface MissionProfileProps {
  name: string,
  origin: string,
  destination: string,
  disabledCategories?: string[]
}

export default function PromotionEditPage(props: any) {
  const classes = useStyles();
  const promotionsCollection = FirebaseService.collection(Collection.PROMOTIONS);

  // initialize
  useEffect(() => {
    // register onSnapshot listener only when editing existing promotions
    let promotionListener = !creatingNewEntry() && promotionsCollection
      .doc(props.match.params.promotionId)
      .onSnapshot((docSnapshot) => {
        const data = FirebaseService.unmarshalSnapshot(docSnapshot);
        setPromotion(data as PromotionProps);
      });

    retrieveAirports();
    retrieveMissionProfiles();

    // cleanup onSnapshot listener
    return () => {
      promotionListener && promotionListener()
    }
  }, [props.location.pathname]);

  // promotion props
  const [promotion, setPromotion] = React.useState<PromotionProps>(emptyPromotion);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // remote data
  const [airports, setAirports] = React.useState<AirportProps[] | null>(null);
  const [missionProfiles, setMissionProfiles] = React.useState<MissionProfileProps[] | null>(null);

  // reject button/dialog props
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState<boolean>(false);

  // misc hooks
  const snackbar = useSnackbar();

  const navigateToPromotionListPage = () => props.history.push('/promotions');

  async function retrieveAirports() {
    const querySnapshot = await FirebaseService.collection(Collection.AIRPORTS).get();
    let data: AirportProps[] = [];
    querySnapshot.forEach(doc => {
      data.push(FirebaseService.unmarshalSnapshot(doc) as AirportProps);
    });
    setAirports(_.sortBy(data, 'name'));
  }

  async function retrieveMissionProfiles() {
    const querySnapshot = await FirebaseService.collection(Collection.MISSION_PROFILES).get();
    let data: MissionProfileProps[] = [];
    querySnapshot.forEach(doc => {
      data.push(FirebaseService.unmarshalSnapshot(doc) as MissionProfileProps);
    });
    setMissionProfiles(_.sortBy(data, 'name'));
  }

  function creatingNewEntry() {
    return props.match.params.promotionId === 'new';
  }

  async function savePromotion(values: PromotionProps) {
    try {
      setIsLoading(true);
      if (creatingNewEntry()) {
        // creating new promotion & navigate to promotion edit page
        const docRef = await promotionsCollection.add(values);
        props.history.replace(props.location.pathname.replace('new', docRef.id));
      } else {
        // editing existing promotion
        await promotionsCollection.doc(props.match.params.promotionId).set(values);
      }
      snackbar.showMessage('Promotion saved successfully');
    } catch (e) {
      snackbar.showMessage('Error saving promotion: ' + e.message)
    } finally {
      setIsLoading(false);
    }
  }

  async function deletePromotion() {
    try {
      setIsDeleteOpen(false);
      setIsDeleting(true);
      await promotionsCollection.doc(props.match.params.promotionId).update({
          deleted: true
        });
      navigateToPromotionListPage()
    } catch (e) {
      snackbar.showMessage('Error: ' + e.message);
      console.error(e)
    } finally {
      setIsDeleting(false);
    }
  }

  function renderToolbar() {
    return (
      <Toolbar className={classes.toolbar}>

        <Tooltip title='Back to Promotions'>
          <IconButton
            onClick={() => navigateToPromotionListPage()}
            className={classes.backIcon}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title='Save Promotion'>
          <div>
            <LoadingButton
              className={classes.toolbarButton}
              variant='contained'
              size='small'
              onClick={() => submitPromotionForm()}
              isLoading={isLoading}
            >
              SAVE
              <CheckIcon className={`${classes.buttonIcon} ${classes.green}`} />
            </LoadingButton>
          </div>
        </Tooltip>

        <Tooltip title='Delete Promotion'>
          <div>
            <LoadingButton
              className={classes.toolbarButton}
              variant='contained'
              size='small'
              onClick={() => setIsDeleteOpen(true)}
              isLoading={isDeleting}
            >
              DELETE
              <CancelIcon className={`${classes.buttonIcon} ${classes.red}`} />
            </LoadingButton>
          </div>
        </Tooltip>

        <AlertDialog
          open={isDeleteOpen}
          title='Confirm Promotion Delete'
          description={'Are you sure you want to delete this promotion?'}
          confirmButtonText='Yes'
          cancelButtonText='No'
          onConfirm={() => deletePromotion()}
          onCancel={() => setIsDeleteOpen(false)}
        />

      </Toolbar>
    );
  }

  function renderPromotionForm() {
    if (!promotion) {
      return null;
    }

    return (
      <PromotionForm
        promotion={promotion}
        airports={airports || []}
        missionProfiles={missionProfiles || []}
        onSubmit={savePromotion}
      />
    )
  }

  return (
    <Container className={classes.container}>
      <Typography variant='h6' gutterBottom>
        {creatingNewEntry() ? 'Create' : 'Edit'} Promotion
      </Typography>

      <Grid container spacing={3}>
        {renderToolbar()}
        {renderPromotionForm()}
      </Grid>
    </Container>
  );
}
