import React, {useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import LoadingButton from '../Components/LoadingButton';
import FirebaseService, {Collection} from '../Services/FirebaseService';
import TextField from "@material-ui/core/TextField";
import {DateUtils} from "../Utils/dateUtils";
import _ from 'lodash'
import {Toolbar} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import Tooltip from "@material-ui/core/Tooltip";
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import MissionConfirmDialog from "../Components/MissionConfirmDialog";
import AlertDialog from "../Components/AlertDialog";
import useSnackbar from "../Hooks/useSnackbar";

const useStyles = makeStyles((theme: Theme) =>
 createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: 'white',
      borderRadius: 10,
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    formControl: {
      minWidth: 200,
    },
    toolbar: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.grey['300'],
      paddingBottom: theme.spacing(),
      paddingLeft: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    toolbarButton: {
      marginRight: theme.spacing(2)
    },
    backIcon: {
      marginLeft: 0,
      marginRight: theme.spacing(3)
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
      fontSize: 20,
    },
    green: {
      color: green['500']
    },
    red: {
      color: red['600']
    }
  }),
);

interface PaymentProps {
  amount: number,
  chargeId: string,
  refundId?: string
}

interface PassengerProps {
  id: string,
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
  weight: number,
}

interface MissionProps {
  id: string,
  searchId: string,
  leadId: string,
  charterPlatform: string,
  category: string,
  sellerQuoteIds: string[],
  profile: string,
  origin: string,
  destination: string,
  date: Date,
  routeTime: number,
  numberOfPassengers: number,
  passengers: PassengerProps[],
  user: string,
  price: number,
  payments?: PaymentProps[];
  status: string,
  flight: string,
}

export default function MissionEditPage(props: any) {
  const classes = useStyles();

  // initialize
  useEffect(() => {
    // register onSnapshot listener
    let missionListener = FirebaseService.collection(Collection.MISSIONS)
      .doc(props.match.params.missionId)
      .onSnapshot((docSnapshot) => {
        const data = FirebaseService.unmarshalSnapshot(docSnapshot);
        setMission(data as MissionProps);
      });

    // cleanup onSnapshot listener
    return () => {
      missionListener()
    }
  }, []);

  // mission props
  const [mission, setMission] = React.useState<MissionProps | null>(null);
  const [openMissionConfirm, setOpenMissionConfirm] = React.useState<boolean>(false);

  // reject button/dialog props
  const [isRejectLoading, setRejectLoading] = React.useState<boolean>(false);
  const [isRejectOpen, setIsRejectOpen] = React.useState<boolean>(false);
  // complete button/dialog props
  const [isCompleteLoading, setCompleteLoading] = React.useState<boolean>(false);
  const [isCompleteOpen, setIsCompleteOpen] = React.useState<boolean>(false);

  // snackbar
  const snackbar = useSnackbar();

  const navigateToMissionListPage = () => props.history.push('/missions');
  const confirmMission = () => setOpenMissionConfirm(true);

  async function rejectMission() {
    try {
      setIsRejectOpen(false);
      setRejectLoading(true);
      await FirebaseService.rejectMission(mission!.id);
    } catch (e) {
      snackbar.showMessage('Error: ' + e.message);
      console.error(e)
    } finally {
      setRejectLoading(false);
    }
  }

  async function completeMission() {
    try {
      setIsCompleteOpen(false);
      setCompleteLoading(true);
      await FirebaseService.completeMission(mission!.id);
    } catch (e) {
      snackbar.showMessage('Error: ' + e.message);
      console.error(e)
    } finally {
      setCompleteLoading(false);
    }
  }

  function isConfirmAllowed() {
    // todo: reuse MissionStatus constant
    const allowedStatuses = ['Deposit-received', 'Booked'];
    return mission !== null && allowedStatuses.includes(mission.status);
  }

  function isRejectAllowed() {
    const disallowedStatuses = ['Completed', 'Canceled'];
    return mission !== null && !disallowedStatuses.includes(mission.status);
  }

  function isCompleteAllowed() {
    return mission !== null && mission.status === 'Paid';
  }

  function renderToolbar() {
    return (
      <Toolbar className={classes.toolbar}>

        <Tooltip title="Back to Missions">
          <IconButton
            onClick={() => navigateToMissionListPage()}
            className={classes.backIcon}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Confirm Mission">
          <div>
            <LoadingButton
              className={classes.toolbarButton}
              variant="contained"
              size='small'
              onClick={() => confirmMission()}
              disabled={!isConfirmAllowed()}
            >
              CONFIRM
              <CheckIcon className={`${classes.buttonIcon} ${isConfirmAllowed() ? classes.green : ''}`} />
            </LoadingButton>
          </div>
        </Tooltip>

        <Tooltip title="Reject Mission">
          <div>
            <LoadingButton
              className={classes.toolbarButton}
              variant="contained"
              size='small'
              onClick={() => setIsRejectOpen(true)}
              disabled={!isRejectAllowed()}
              isLoading={isRejectLoading}
            >
              REJECT
              <CancelIcon className={`${classes.buttonIcon} ${isConfirmAllowed() ? classes.red : ''}`} />
            </LoadingButton>
          </div>
        </Tooltip>

        <Tooltip title="Complete Mission">
          <div>
            <LoadingButton
              className={classes.toolbarButton}
              variant="contained"
              size='small'
              onClick={() => setIsCompleteOpen(true)}
              disabled={!isCompleteAllowed()}
              isLoading={isCompleteLoading}
            >
              COMPLETE
              <FlightLandIcon className={`${classes.buttonIcon}`}/>
            </LoadingButton>
          </div>
        </Tooltip>

        <AlertDialog
          open={isRejectOpen}
          title='Confirm Mission Reject'
          description={'Are you sure you want to reject this mission?'}
          confirmButtonText='Yes'
          cancelButtonText='No'
          onConfirm={() => rejectMission()}
          onCancel={() => setIsRejectOpen(false)}
        />

        <AlertDialog
          open={isCompleteOpen}
          title='Confirm Mission Complete'
          description={'Are you sure you want to complete this mission?'}
          confirmButtonText='Yes'
          cancelButtonText='No'
          onConfirm={() => completeMission()}
          onCancel={() => setIsCompleteOpen(false)}
        />

      </Toolbar>
    );
  }

  function calculateAmountPaid() {
    if (!mission || !mission.payments) {
      return 0;
    }
    return _.sumBy(mission!.payments, (payment: PaymentProps) => payment.refundId ? 0 : payment.amount || 0);
  }

  function renderMissionForm() {
    if (!mission) {
      return null;
    }

    // todo: extract price formatting into its own function & reuse payment calculation with 'functions' project
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date"
            fullWidth
            value={DateUtils.formatDateTime(mission.date, 'UTC') + ' UTC'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Status"
            fullWidth
            value={_.startCase(mission.status)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Charter Platform"
            fullWidth
            value={mission.charterPlatform}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Category"
            fullWidth
            value={mission.category}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Origin"
            fullWidth
            value={mission.origin}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Destination"
            fullWidth
            value={mission.destination}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Price"
            fullWidth
            value={'$' + mission.price / 100}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Paid"
            fullWidth
            value={'$' + calculateAmountPaid() / 100}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} hidden={!mission.leadId}>
          <TextField
            label="Avinode Lead ID"
            fullWidth
            value={mission.leadId}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Mission Details
      </Typography>

      { mission &&
        <MissionConfirmDialog
          open={openMissionConfirm}
          onClose={() => setOpenMissionConfirm(false)}
          missionId={mission.id}
          price={mission.price / 100}
        />
      }

      <Grid container spacing={3}>
        {renderToolbar()}
        {renderMissionForm()}
      </Grid>
    </Container>
  );
}
