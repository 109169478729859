// todo: reuse this class with firebase/functions/src/Utilities/currencyUtils.ts
type Currency = 'USD'

export class CurrencyUtils {
  static toCents(amount: number) {
    if (amount === null || amount === undefined) {
      return null;
    }

    return amount * 100;
  }

  static formatAsCurrency = (amountInCents: number, currency: Currency = 'USD', additionalOptions:Intl.NumberFormatOptions = {}) => {
    // unset/invalid amount
    if (amountInCents === null || amountInCents === undefined) {
      return null;
    }

    return (amountInCents / 100).toLocaleString('EN-us', {
      style: 'currency',
      currency,
      ...additionalOptions
    })
  }
}
