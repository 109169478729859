import * as firebase from 'firebase'
import {User} from "../Pages/UserEditPage";
import _ from 'lodash';
import {MissionConfirmParams} from "../Components/MissionConfirmDialog";

export enum Collection {
  AIRPORTS = 'airports',
  USERS = 'users',
  MISSIONS = 'missions',
  MISSION_PROFILES = 'missionProfiles',
  PROMOTIONS = 'promotions'
}

class FirebaseService {
  static isAuthenticated = false;
  static onAuthStateChangedListener:((isAuthenticated: boolean) => void) | undefined;

  static initialize() {
    // Initialize Firebase
    var config = {
      apiKey: "AIzaSyB1X-bdgcXuTcIC7A0tx9-7nGCATd99sJs",
      authDomain: "jetline-test.firebaseapp.com",
      databaseURL: "https://jetline-test.firebaseio.com",
      projectId: "jetline-test",
      storageBucket: "jetline-test.appspot.com",
      messagingSenderId: "1075867463291"
    };
    firebase.initializeApp(config);
  }

  static collection(collection: Collection) {
    return firebase.firestore().collection(collection)
  }

  static auth() {
    return firebase.auth()
  }

  static login(email: string, password: string) {
    return this.auth().signInWithEmailAndPassword(email, password)
  }

  static unmarshalSnapshot (snapshot: firebase.firestore.DocumentSnapshot) {
    if (!snapshot) {
      return null
    }
    // convert Timestamp objects to Date
    let data = _.mapValues(snapshot.data(), function (val) {
      if (val && _.isFunction(val.toDate)) {
        return val.toDate()
      }
      return val
    });
    // add ID as its own property
    data.id = snapshot.id;
    return data;
  }

  // Cloud Functions
  static registerNewUser(user: User) {
    return firebase.functions().httpsCallable('registerNewUser')(user)
  }

  static setUserPassword(uid: string, email: string, password: string, passwordConfirm: string) {
    return firebase.functions().httpsCallable('setUserPassword')({uid, email, password, passwordConfirm})
  }

  static confirmMission(missionConfirmParams: MissionConfirmParams) {
    return firebase.functions().httpsCallable('confirmMission')(missionConfirmParams)
  }

  static rejectMission(missionId: string) {
    return firebase.functions().httpsCallable('rejectMission')({missionId})
  }

  static completeMission(missionId: string) {
    return firebase.functions().httpsCallable('completeMission')({missionId})
  }

}

FirebaseService.initialize();

firebase.auth().onAuthStateChanged(function (user) {
  FirebaseService.isAuthenticated = !!user;
  if (FirebaseService.onAuthStateChangedListener) {
    FirebaseService.onAuthStateChangedListener(FirebaseService.isAuthenticated)
  }
});

export default FirebaseService;
