import React, {CSSProperties, HTMLAttributes} from 'react';
import {createStyles, emphasize, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MuiTextField, {BaseTextFieldProps} from '@material-ui/core/TextField';
import _ from 'lodash'
import CancelIcon from '@material-ui/icons/Cancel';
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import {ControlProps} from 'react-select/src/components/Control';
import Paper from '@material-ui/core/Paper';
import {MenuProps, NoticeProps} from 'react-select/src/components/Menu';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import {ValueContainerProps} from 'react-select/src/components/containers';
import {ValueType} from 'react-select/src/types';
import {MultiValueProps} from 'react-select/src/components/MultiValue';
import {OptionProps} from 'react-select/src/components/Option';
import {PlaceholderProps} from 'react-select/src/components/Placeholder';
import {SingleValueProps} from 'react-select/src/components/SingleValue';
import clsx from 'clsx';
import {Omit} from '@material-ui/types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import {Field, FormikProps, withFormik} from 'formik';
import {TextField} from 'formik-material-ui';
import * as yup from 'yup';
import {AirportProps, MissionProfileProps, PromotionCategoryProps, PromotionProps} from "../../Pages/PromotionEditPage";
import PromotionImage from "./PromotionImage";
import {PromotionCategoryTable} from "./PromotionCategoryTable";
import {DateTimePicker} from "@material-ui/pickers";
import {WrapperVariant} from "@material-ui/pickers/wrappers/Wrapper";
import moment from 'moment-timezone'

// todo: there's definitely a better way to handle formik form submission than setting/exporting this function
export let submitPromotionForm: () => void;

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      formControl: {
        minWidth: 200,
      },
      formLabel: {
        alignSelf: 'center',
        paddingRight: theme.spacing(2)
      },
      input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
      },
      valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
      },
      chip: {
        margin: theme.spacing(0.5, 0.25),
      },
      chipFocused: {
        backgroundColor: emphasize(
          theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
          0.08,
        ),
      },
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
      singleValue: {
        fontSize: 16,
      },
      placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
      },
      paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
      }
    }
  ));

const promotionSchema = yup.object().shape(
  {
    name: yup.string()
      .required('Required'),
    headline: yup.string()
      .required('Required'),
    description: yup.string()
      .required('Required'),
    backgroundImage: yup.string()
      .url()
      .required('Required'),
    origin: yup.array().of(yup.string().min(1))
      .required('At least one origin is required'),
    destination: yup.array().of(yup.string().min(1))
      .required('At least one destination is required'),
    visible: yup.boolean()
      .required('Required'),
    deleted: yup.boolean()
      .required('Required'),
    priorityLevel: yup.number()
      .min(0)
      .required('Required'),
    startDate: yup.date()
      .nullable(),
    endDate: yup.date()
      .required('Required'),
    validDays: yup.array().of(yup.number())
      .min(1)
      .max(7)
      .required('Required'),
    categories: yup.array().of(yup.object().shape({
        category: yup.string().required(),
        price: yup.number()
          .min(0)
      }
    ))
  });

const selectStyles = {
  input: (base: CSSProperties) => ({
    ...base,
    '& input': {
      font: 'inherit',
    },
  }),
};

function NoOptionsMessage(props: NoticeProps<AirportProps>) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({inputRef, ...props}: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<AirportProps>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {classes, TextFieldProps},
  } = props;

  return (
    <MuiTextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<AirportProps>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<AirportProps>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<AirportProps>, 'innerProps'>>;

function Placeholder(props: MuiPlaceholderProps) {
  const {selectProps, innerProps = {}, children} = props;
  return (
    <Typography color='textSecondary' className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<AirportProps>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<AirportProps>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props: MultiValueProps<AirportProps>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props: MenuProps<AirportProps>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const days = [{
  label: 'Monday', value: 1,
}, {
  label: 'Tuesday', value: 2,
}, {
  label: 'Wednesday', value: 3,
}, {
  label: 'Thursday', value: 4,
}, {
  label: 'Friday', value: 5,
}, {
  label: 'Saturday', value: 6,
}, {
  label: 'Sunday', value: 7,
}];

function isDaySelected(validDays: number[] | undefined, value: number) {
  return (validDays || []).includes(value);
}

const airportsToCodes = (airports: ValueType<AirportProps>): string[] => {
  return _.map(airports as any, 'code')
};

const daySelectors = (validDays: number[] = [], onChange: (days: number[]) => void) => {
  return days.map(
    day => (
      <FormControlLabel
        key={`cb-${day.value}`}
        control={
          <Checkbox
            checked={isDaySelected(validDays, day.value)}
            onChange={event => onChange(handleValidDayChange(day.value, event.target.checked, validDays))}
            value={day.value} />
        }
        label={day.label}
      />
    )
  )
};

const handleValidDayChange = (dayIndex: number,
                              checked: boolean,
                              validDays: number[] = []) => {
  if (checked) {
    validDays.push(dayIndex);
  } else {
    validDays = _.without(validDays, dayIndex);
  }
  return _.sortBy(validDays);
};

const datepickerDefaultProps = {
  variant: 'dialog' as WrapperVariant,
  clearable: true,
  onError: console.log,
  inputRef: undefined,
  ref: undefined,
  className: undefined,
  innerRef: undefined,
  rows: undefined,
  rowsMax: undefined,
  format: 'lll',
  style: {},
  helperText: `* ${moment.tz(moment.tz.guess(true)).zoneAbbr()} time`
};

interface PromotionFormProps {
  airports: AirportProps[],
  missionProfiles: MissionProfileProps[],
  promotion: PromotionProps,
  onSubmit: (values: PromotionProps) => Promise<void>
}

const PromotionForm = (props: PromotionFormProps & FormikProps<PromotionProps>) => {
  const classes = useStyles();
  let {values, errors, touched, handleBlur, handleChange, setFieldValue} = props;
  submitPromotionForm = props.submitForm;
  const airportMap = _.groupBy(props.airports, 'code');
  const missionProfileMap = mapMissionProfilesByOrigin();

  function getAirportByCode(code: string) {
    return _.first(airportMap[code]);
  }

  function mapMissionProfilesByOrigin() {
    return _(props.missionProfiles)
      .filter(mp => isActiveAirport(mp.origin))
      .groupBy('origin')
      .value();
  }

  function isActiveAirport(airportCode: string): boolean {
    const airport = _.first(airportMap[airportCode]);
    if (!airport) {
      return false;
    }
    return airport.status !== 'Disabled';
  }

  function getAvailableOrigins() {
    return _.filter(props.airports, a => missionProfileMap[a.code] !== undefined);
  }

  function getAvailableDestinations() {
    let destinations: AirportProps[] = [];
    const selectedOrigins = values.origin || [];
    for (const origin of (selectedOrigins)) {
      const destinationsByOrigin = missionProfileMap[origin];
      const destinationAirports = _.map(destinationsByOrigin, mp => getAirportByCode(mp.destination)!);
      destinations = destinations.concat(destinationAirports);
    }
    return _.uniq(destinations);
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Field
          label='Name'
          name='name'
          fullWidth
          required
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          label='Headline'
          name='headline'
          fullWidth
          required
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Field
          label='Description'
          name='description'
          multiline
          fullWidth
          rows={2}
          rowsMax={4}
          required
          component={TextField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            inputId='origin'
            TextFieldProps={{
              label: 'Origin',
              name: 'origin',
              error: touched.origin !== undefined && errors.origin !== undefined,
              helperText: touched.origin !== undefined && errors.origin,
              onBlur: handleBlur,
              required: true,
              InputLabelProps: {
                htmlFor: 'react-select-single',
                shrink: true
              },
            }}
            placeholder='Select Airports...'
            getOptionLabel={airport => `${airport.name} ${airport.code}`}
            getOptionValue={airport => `${airport.code}`}
            options={getAvailableOrigins()}
            value={(props.airports).filter(airport => _.includes(values.origin, airport.code))}
            components={components}
            onChange={(airports: ValueType<AirportProps>) => setFieldValue('origin', airportsToCodes(airports))}
            isMulti
          />
        </NoSsr>
      </Grid>

      <Grid item xs={12} sm={6}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            isDisabled={_.isEmpty(values.origin)}
            inputId='destination'
            TextFieldProps={{
              label: 'Destination',
              name: 'destination',
              error: touched.destination !== undefined && errors.destination !== undefined,
              helperText: touched.destination !== undefined && errors.destination,
              onBlur: handleBlur,
              required: true,
              InputLabelProps: {
                htmlFor: 'react-select-single',
                shrink: true,
              },
            }}
            placeholder='Select Airports...'
            getOptionLabel={airport => `${airport.name} ${airport.code}`}
            getOptionValue={airport => `${airport.code}`}
            options={getAvailableDestinations()}
            name='destination'
            value={(props.airports).filter(airport => _.includes(values.destination, airport.code))}
            components={components}
            onChange={(airports: ValueType<AirportProps>) => setFieldValue('destination',
                                                                           airportsToCodes(airports))}
            isMulti
          />
        </NoSsr>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          label='Priority Level'
          name='priorityLevel'
          type='number'
          required
          component={TextField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              name='visible'
              checked={values.visible}
              onChange={handleChange}
            />
          }
          label='Visible'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateTimePicker
          {...datepickerDefaultProps}
          name='startDate'
          label='Start Date'
          value={values.startDate || null}
          onChange={date => setFieldValue('startDate', date ? date.toDate() : null)}
          error={errors.startDate !== undefined}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateTimePicker
          {...datepickerDefaultProps}
          name='endDate'
          label='End Date'
          value={values.endDate || null}
          onChange={date => setFieldValue('endDate', date ? date.toDate() : null)}
          required
          error={errors.endDate !== undefined}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormGroup row>
          <FormLabel
            component='legend'
            className={classes.formLabel}
            error={errors.validDays !== undefined}
          >
            Valid Days:
          </FormLabel>
          {daySelectors(values.validDays, days => setFieldValue('validDays', days))}
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          label='Background Image'
          name='backgroundImage'
          placeholder='Paste image URL here...'
          type='URL'
          fullWidth
          required
          component={TextField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <PromotionImage
          url={values.backgroundImage}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <PromotionCategoryTable
          rows={values.categories}
          onChange={(selectedCategories: PromotionCategoryProps[]) => setFieldValue('categories',
                                                                                    selectedCategories)}
        />
      </Grid>
    </React.Fragment>
  );
};

export default withFormik<PromotionFormProps, PromotionProps>(
  {
    handleSubmit: async (values, actions) => {
      actions.props.onSubmit && await actions.props.onSubmit(values);
      actions.setSubmitting(false);
    },
    mapPropsToValues: (props) => {
      return props.promotion;
    },
    enableReinitialize: true,
    validationSchema: promotionSchema
  }
)(PromotionForm)
