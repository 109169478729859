import React, {forwardRef, useEffect} from 'react';
import MaterialTable from 'material-table';
import {PromotionProps} from '../Pages/PromotionEditPage';
// material-table
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import FirebaseService, {Collection} from "../Services/FirebaseService";
import _ from "lodash";
import {Checkbox} from "@material-ui/core";
import {RouteComponentProps} from 'react-router'
import Button from "@material-ui/core/Button";

// todo: extract into its own component
const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />)
};

interface PromotionModel extends PromotionProps {
  id: string
}

export function PromotionListTable(props: RouteComponentProps) {

  const [promotions, setPromotions] = React.useState<PromotionModel[]>([]);

  useEffect(() => {
    let snapshotListener = FirebaseService.collection(Collection.PROMOTIONS)
      .where('deleted', '==', false)
      .onSnapshot((querySnapshot) => {
        let promotions: PromotionModel[] = [];
        querySnapshot.forEach(doc => {
          const data = FirebaseService.unmarshalSnapshot(doc) as PromotionModel;
          promotions.push(data);
        });
        setPromotions(promotions);
      });

    // cleanup onSnapshot listener
    return () => {
      snapshotListener();
    }
  }, []);

  function formatValidDays(validDays:number[] = []) {
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return validDays.map(d => dayNames[d - 1]).join(', ');
  }

  function formatAirports(airports:string[] = []) {
    return airports.join(', ')
  }

  function navigateToPromotionEditPage (e: React.MouseEvent | undefined, promoId: string) {
    return props.history.push(`${props.match.path}/${promoId}`)
  }

  return <MaterialTable
    title='Promotions'
    columns={[{
      title: 'Priority Level',
      field: 'priorityLevel' as 'priorityLevel',
    },{
      title: 'Name',
      field: 'name' as 'name',
    }, {
      title: 'Headline',
      field: 'headline' as 'headline'
    }, {
      title: 'Description',
      render: promo => _.truncate(promo.description),
    }, {
      title: 'Origin',
      render: promo => formatAirports(promo.origin)
    }, {
      title: 'Destination',
      render: promo => formatAirports(promo.destination)
    }, {
      title: 'Valid Days',
      render: promo => formatValidDays(promo.validDays)
    }, {
      title: 'Visible',
      render: promo => <Checkbox
        checked={promo.visible}
        disabled
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
      />
    }]}
    data={promotions}
    onRowClick={(e, promo) => navigateToPromotionEditPage(e, promo!.id)}
    options={{
      search: false,
      pageSize: 10
    }}
    actions={[
      {
        icon: 'add',
        tooltip: 'Add Promotion',
        isFreeAction: true,
        onClick: event => navigateToPromotionEditPage(event, 'new')
      }
    ]}
    components={{
      Action: props => (
        <Button
          onClick={event => props.action.onClick(event)}
          color='primary'
          variant='contained'
        >
          {props.action.tooltip}
        </Button>
      ),
    }}
    icons={tableIcons}
  />
}
