import React from 'react';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import Navigator from '../Components/Navigator';
import Header from '../Components/Header';
import UserListPage from './UserListPage';
import {Redirect, Route, RouteComponentProps} from 'react-router';
import UserEditPage from './UserEditPage';
import MissionListPage from "./MissionListPage";
import MissionEditPage from "./MissionEditPage";
import {PromotionListTable} from "./PromotionListPage";
import PromotionEditPage from "./PromotionEditPage";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const snack = require('material-ui-snackbar-provider');

const styles = (theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        minHeight: '100vh',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      mainContent: {
        flex: 1,
        padding: '48px 36px 0',
        background: '#eaeff1',
      },
    }
  );

const drawerWidth = 256;

type State = {
  open: boolean,
  mobileOpen: boolean
};

class HomePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps, State> {
  state = {
    open: false,
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({mobileOpen: !state.mobileOpen}));
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <nav className={classes.drawer}>
          <Hidden smUp implementation='js'>
            <Navigator
              PaperProps={{style: {width: drawerWidth}}}
              variant='temporary'
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation='css'>
            <Navigator PaperProps={{style: {width: drawerWidth}}} />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <snack.SnackbarProvider SnackbarProps={{autoHideDuration: 5000}}>
              <Header onDrawerToggle={this.handleDrawerToggle} />
              <main className={classes.mainContent}>
                <Route exact path='/' render={() => (
                  <Redirect to='/users' />
                )} />

                {/* Users */}
                <Route exact path='/users' component={UserListPage} />
                <Route path='/users/:userId' component={UserEditPage} />

                {/* Missions */}
                <Route exact path='/missions' component={MissionListPage} />
                <Route path='/missions/:missionId' component={MissionEditPage} />

                {/* Promotions */}
                <Route exact path='/promotions' component={PromotionListTable} />
                <Route path='/promotions/:promotionId' component={PromotionEditPage} />

              </main>
            </snack.SnackbarProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
