let moment = require("moment");

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const TIME_FORMAT_24H = 'HH:mm';

export class DateUtils {
  static formatDate(date: string | Date | undefined, timezone?: string) {
    if (!date) {
      return undefined;
    }

    if (!timezone) {
      return moment(date).format(ISO_DATE_FORMAT);
    }

    return moment.tz(date, timezone).format(ISO_DATE_FORMAT);
  }

  static formatTime(time: string | Date, timezone?: string) {
    if (!timezone) {
      return moment(time).format(TIME_FORMAT_24H);
    }

    return moment.tz(time, timezone).format(TIME_FORMAT_24H);
  }

  static formatDateTime(datetime: string | Date, timezone?: string) {
    if (!timezone) {
      return moment(datetime).format(DATETIME_FORMAT);
    }

    return moment.tz(datetime, timezone).format(DATETIME_FORMAT);
  }
}
