import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import _ from 'lodash';
import {TextField} from "@material-ui/core";
import FirebaseService from "../Services/FirebaseService";
import LoadingButton from "../Components/LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   main: {
     width: 'auto',
     display: 'block', // Fix IE 11 issue.
     marginLeft: theme.spacing(3),
     marginRight: theme.spacing(3),
     [theme.breakpoints.up(400 + theme.spacing(6))]: {
       width: 400,
       marginLeft: 'auto',
       marginRight: 'auto',
     },
   },
   paper: {
     marginTop: theme.spacing(8),
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
   },
   logo: {
     margin: theme.spacing(4),
     marginBottom: theme.spacing(2),
     width: 200
   },
   form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(),
   },
   submit: {
     marginTop: theme.spacing(3),
   },
   successMessage: {
     textAlign: 'center'
   },
   error: {
     marginTop: theme.spacing(2),
     color: 'red'
   }
  })
);

export default function PasswordResetPage() {
  const classes = useStyles();
  const params = new URLSearchParams(window.location.search);

  // state
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [error, setError] = useState('');
  let [finished, setFinished] = useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // componentDidMount alternative using hooks
  useEffect(() => {
    parseQueryParams()
  }, []);

  function parseQueryParams() {
    const emailParam = params.get('email');
    if (emailParam && email !== emailParam) {
      setEmail(emailParam);
    }
  }

  function isPasswordValid() {
    return _.size(password) >= 8;
  }

  function isPasswordConfirmed() {
    return password === passwordConfirm;
  }

  function allValuesSet() {
    return email &&
           params.get('token') &&
           password &&
           passwordConfirm &&
           isPasswordValid() &&
           isPasswordConfirmed()
  }

  function getPasswordMinLengthError() {
    return password && _.size(password) < 8 ? "8 characters or more" : "";
  }

  function getPasswordConfirmError() {
    return password && passwordConfirm && password !== passwordConfirm ? "passwords do not match" : "";
  }

  async function setUserPassword() {
    setError('');
    setFinished(false);
    if (!allValuesSet()) {
      return;
    }

    try {
      setIsLoading(true);
      await FirebaseService.setUserPassword(params.get('token')!, email, password, passwordConfirm);
      setFinished(true);
    } catch (e) {
      setError('unable to set password')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <main className={classes.main}>
      <CssBaseline/>
      <Paper className={classes.paper}>
        <img
          alt="Jetline Enterprises Inc."
          src="/images/logo-white-bg.png"
          className={classes.logo}
        />
        {!finished
         ? renderPasswordForm()
         : renderFinished()
        }
      </Paper>
    </main>
  );

  function renderPasswordForm() {
    return (
      <form className={classes.form}>
        <TextField
          required
          fullWidth
          label="Email"
          autoComplete="off"
          value={email}
          onChange={e => setEmail(e.target.value)}
          margin='normal'
        />
        <TextField
          required
          fullWidth
          label="Password"
          type="password"
          autoComplete="off"
          helperText={getPasswordMinLengthError()}
          value={password}
          onChange={e => setPassword(e.target.value)}
          margin='normal'
        />
        <TextField
          required
          fullWidth
          label="Confirm"
          type="password"
          autoComplete="off"
          value={passwordConfirm}
          helperText={getPasswordConfirmError()}
          onChange={e => setPasswordConfirm(e.target.value)}
          margin='normal'
        />

        {renderError()}

        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!allValuesSet()}
          onClick={setUserPassword}
          isLoading={isLoading}
        >
          Set Password
        </LoadingButton>
      </form>
    )
  }

  function renderError() {
    const errorString = error ? 'Error: ' + error : '';
    return (
      <div className={classes.error}>
        &nbsp; {errorString}
      </div>
    )
  }

  function renderFinished() {
    return (
      <React.Fragment>
      <h3>Password set successfully</h3>
      <span className={classes.successMessage}>You can now login to Jetline app using your email and password</span>
      </React.Fragment>
    )
  }
}
