import React, {forwardRef, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {PromotionCategoryProps} from '../../Pages/PromotionEditPage';
// material-table
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {CurrencyUtils} from '../../Utils/currencyUtils';
import Input, {InputProps} from "@material-ui/core/Input";
import {InputAdornment} from "@material-ui/core";
import _ from 'lodash';

const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />)
};

// todo: reuse from firebase/functions/src/Avinode/aircraftCategories.ts
const categories = {
  'SF50': 'SF50',
  'Turbo prop': 'Turboprops',
  'Entry level jet (VLJ)': 'Very Light Jets',
  'Light jet': 'Light Jets',
  'Super light jet': 'Super Light Jets',
  'Midsize jet': 'Mid-size Jets',
  'Super midsize jet': 'Super Mid-size Jets'
};

function formatPrice(price: number | undefined) {
  if (price === undefined || price === null) {
    return '';
  }
  return CurrencyUtils.formatAsCurrency(price, 'USD', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

function PriceInput(props: InputProps) {
  const {value, onChange, ...other} = props;
  const [valueInDollars, setValueInDollars] = useState<Number | undefined>(
    isNaN(value as number) ? undefined : value as number / 100
  );

  function _onChange(e: any) {
    const dollars = e.target.value;
    setValueInDollars(dollars);
    if (!isNaN(parseFloat(dollars)) && isFinite(dollars)) {
      const event = {...e};
      event.target.value = dollars * 100;
      onChange && onChange(event);
    }
  }

  return (
    <Input
      startAdornment={<InputAdornment position='start'>$</InputAdornment>}
      value={valueInDollars}
      onChange={_onChange}
      {...other}
    />
  );
}

export function PromotionCategoryTable(props: {
  rows: PromotionCategoryProps[] | undefined,
  onChange: (selectedCategories: PromotionCategoryProps[]) => void
}) {

  const [selectedCategories, setSelectedCategories] = React.useState<PromotionCategoryProps[] | undefined>();
  useEffect(() => {
    setSelectedCategories(props.rows);
  }, [props.rows]);

  function selectCategories(categories: PromotionCategoryProps[]) {
    setSelectedCategories(categories);
    if (_.isFunction(props.onChange)) {
      props.onChange(categories);
    }
  }

  function addCategoryRow(category: PromotionCategoryProps): Promise<void> {
    return new Promise((resolve, reject) => {
      const misformattedPrice = category.price !== null && category.price !== undefined && isNaN(category.price);
      if (misformattedPrice || Number(category.price) < 0) {
        return reject();
      }
      resolve();
      let data = [...selectedCategories || []];
      selectCategories(data.concat(category));
    });
  }

  function updateCategoryRow(newData: PromotionCategoryProps, oldData?: PromotionCategoryProps): Promise<void> {
    return new Promise(resolve => {
      resolve();
      if (!selectedCategories) {
        return;
      }
      let data = [...selectedCategories];
      data[data.indexOf(oldData!)] = newData;
      selectCategories(data);
    });
  }

  function deleteCategoryRow(oldData: PromotionCategoryProps): Promise<void> {
    return new Promise(resolve => {
      resolve();
      if (!selectedCategories) {
        return;
      }
      let data = [...selectedCategories];
      data.splice(data.indexOf(oldData), 1);
      selectCategories(data);
    });
  }

  return <MaterialTable
    title='Categories'
    columns={[{
      title: 'Category',
      field: 'category',
      lookup: categories,
    }, {
      title: 'Price',
      field: 'price',
      render: category => formatPrice(category.price),
      type: 'numeric',
      editComponent: props => (
        <PriceInput
          value={props.value}
          onChange={e => {
            return props.onChange(Number(e.target.value));
          }}
        />
      )
    }]}
    data={selectedCategories || []}
    options={{
      search: false,
      paginationType: 'stepped',
      maxBodyHeight: 300
    }}
    icons={tableIcons}
    editable={{
      onRowAdd: addCategoryRow,
      onRowUpdate: updateCategoryRow,
      onRowDelete: deleteCategoryRow
    }}
  />
}
