import React from 'react';
import classNames from 'classnames';
import {Theme} from '@material-ui/core/styles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Drawer, {DrawerProps} from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import FlightIcon from '@material-ui/icons/Flight';
import PromotionIcon from '@material-ui/icons/LocalOffer';
import createStyles from "@material-ui/core/styles/createStyles";
import {Avatar} from "@material-ui/core";
import {NavLink} from "react-router-dom";

const categories = [
  {
    id: 'Operations',
    children: [
      {id: 'Users', link: '/users', icon: <PersonIcon/>, active: true},
      {id: 'Missions', link: '/missions', icon: <FlightIcon/>, active: false},
      {id: 'Promotions', link: '/promotions', icon: <PromotionIcon/>, active: false}
    ],
  }
];

const styles = (theme: Theme) =>
  createStyles(
    {
      categoryHeader: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      categoryHeaderPrimary: {
        color: theme.palette.common.white,
      },
      item: {
        paddingTop: 4,
        paddingBottom: 4,
        color: 'inherit'
      },
      itemCategory: {
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: 16,
        paddingBottom: 16,
      },
      firebase: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
      },
      itemActionable: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      },
      itemPrimary: {
        color: 'inherit',
        fontSize: theme.typography.fontSize,
        '&$dense': {
          fontSize: theme.typography.fontSize,
        },
      },
      dense: {},
      divider: {
        marginTop: theme.spacing(2),
      },
      link: {
        textDecoration: 'inherit',
        color: 'rgba(255, 255, 255, 0.7)',
      },
      activeLink: {
        color: theme.palette.primary.main,
      }
    });

class Navigator extends React.Component<WithStyles<typeof styles> & DrawerProps> {
  render() {
    const {classes, ...other} = this.props;

    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
            <Avatar alt="Jetline Enterprises Inc." src="/images/logo_red.png"/>
            Jetline Admin
          </ListItem>

          {categories.map(({id, children}) => (
            <React.Fragment key={id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {id}
                </ListItemText>
              </ListItem>
              {children.map(({id: childId, link, icon, active}) => (
                <NavLink
                  key={childId}
                  className={classes.link}
                  to={link}
                  activeClassName={classes.activeLink}
                >
                  <ListItem
                    button
                    dense
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                    )}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        dense: classes.dense,
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                </NavLink>
              ))}
              <Divider className={classes.divider}/>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(Navigator);
