import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface AlertDialogProps {
  open: boolean,
  title: string,
  description: string,
  confirmButtonText?: string,
  onConfirm: () => void
  cancelButtonText?: string
  onCancel: () => void
}

export default function AlertDialog(props: AlertDialogProps) {

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onCancel()}>
          {props.cancelButtonText || 'Cancel'}
        </Button>
        <Button onClick={() => props.onConfirm()} color="primary" variant='contained' autoFocus>
          {props.confirmButtonText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
